import React, { PureComponent, useState, useCallback, useEffect } from "react";
import CountDownBlock from "./CountDownBlock";
import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"
import PageContentHeadSwitch from "../components/PageCententHeadSwitch"
import { TimelineMax, Linear, Expo, gsap } from 'gsap'

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {


    InViewObserver()
    
    var body = document.querySelector('body');
    var wrap = document.querySelector('#wrap');
    var tag_list = document.querySelectorAll('.tag_list');
    var tag_loop = document.querySelector('.tag_loop');
    var tag_item = document.querySelectorAll('.tag_item');
    var section_link_btn = document.querySelectorAll('.section_link_btn');
    var cursor = document.querySelector('.cursor');
    var cursor_visual = document.querySelector('.cursor_visual');

    wrap.style.height = 'initial';
    wrap.style.overflowX = 'initial';

		section_link_btn.forEach(function(el) {
      var section_link = new TimelineMax({ repeat: -1 });
      section_link.staggerFromTo(el.querySelector('.btn_bg'), 10, { rotate: 0, ease: Linear.easeNone }, { rotate: '360deg', ease: Linear.easeNone });
      el.addEventListener('mouseenter', function () {
        section_link.timeScale(.25);
      });
      el.addEventListener('mouseleave', function () {
        section_link.timeScale(1);
      });
    })
    
		tag_list.forEach(function(el) {
      var tag_looping = new TimelineMax({ repeat: -1 });
			tag_looping.staggerFromTo(el, 20, { xPercent: 0, ease: Linear.easeNone }, { xPercent: -100, ease: Linear.easeNone });
      tag_loop.addEventListener('mouseenter', function () {
        tag_looping.timeScale(.5);
        body.classList.add('hide_cursor');
      });
      tag_loop.addEventListener('mouseleave', function () {
        tag_looping.timeScale(1);
        body.classList.remove('hide_cursor');
      });
		})

		tag_item.forEach(function(el) {
      var tag_item_visual = el.dataset.visual;
			el.addEventListener("mouseenter",  function(){
        cursor.classList.add('tag_loop_active');
        cursor_visual.classList.add(tag_item_visual);
			});
			el.addEventListener("mouseleave",  function(){
        cursor.classList.remove('tag_loop_active');
				cursor_visual.classList.remove(tag_item_visual);
			});
    })
    

    var figure_overlay = document.querySelector('.figure_overlay');
    figure_overlay.addEventListener('click', function(){
      var new_height = figure_overlay.querySelector('img').offsetHeight;
      figure_overlay.style.height = new_height + 'px';
      this.classList.add('open');
    });
  

  }

  componentWillUnmount() {}

  render() {

    var next_visual = require('../images/home/project_d.png');

    return (
      // <CountDownBlock blockType="comingSoon" targetDate={new Date(2020, 4, 27)}>
      //   <h4>《駐村編輯》</h4>
      //   <h5>讓社區找到屬於自己的樣子</h5>
      //   <h6>策展人/林書豪 社區營造工作者</h6>
      //   <p>將於 4/29 上線</p>
      // </CountDownBlock>
      
      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">策展人/林書豪
            <PageContentHeadSwitch/>
          </h2>
          <h1 className="main_title">駐村編輯</h1>
        </div>

        <div className="page_content">

          <section className="row_area_s project_c_1_a">
            <div className="column_5">
              <div className="context">
                <p>「駐村」，是藝術能長久深入和地方發生關係的好方法。進駐在地，增廣見聞，累積人脈，並且從中獲得靈感，進一步回饋社區。甚至透過「藝術共創」的方式，製造更大的共鳴。「駐村編輯」的角色誕生於這樣的脈絡中。他是連結並協調進駐藝術家和社群的中介，猶如特派記者，針對地方進行田野調查，熟習產業、市場、和文化習俗，參加節慶地方繞境、特產採收、環境打掃，甚或向職人學習技藝。透過一次又一次親身參與，與當地人「搏感情」，建立非正式的親密連結及信任。除了實體空間以外，還延伸出線上的虛擬網絡與社群連結。有了這些累積，才有機會聚集能量，邀請大家一同從事藝術共創，產生更大的共鳴。</p>
                <p>這項策展計畫意欲展現，駐村編輯的過程，與「編輯力」的潛力。首先進行社區的田野調查，對象有的是藝術家，有的是創業者，有的和在地有密切連結。透過尋訪他們逐步建立地方的職人黃頁，並在線上放置職人地圖，佐以插畫形式表現。其次於展覽期間，策展人帶領包括「萬華文史」、「社區藝術」、和「食物記憶」三場不同主題的走讀，每次各有一位達人參與。結束後舉辦工作坊進行討論。最後討論的內容、走讀時照片與文字紀錄也更新於線上。讓原本零散的職人們、地方的民眾彼此牽線，交織出更龐大堅實的社會網絡，並嘗試引發未來長遠的、更大層級的藝術共創。</p>
              </div>
            </div>
          </section>


          <section className="row_area_s project_c_1_c">
            <div className="column_4">
              <div className="context">
                <h3>何謂駐村編輯</h3>
                <p>駐村編輯是一個概念，目的在將公共藝術目前的形式做一個全新的思考，嘗試藉由建立一個系統與機制讓各個社區得以藉此重新找到自己的樣子，更期望藉由「駐村編輯」的概念，讓社宅與所在的社區能和諧正向的共存。</p>
              </div>
            </div>
          </section> 

          <section className="row_area_s project_c_1_e tag_loop_row">
            <div className="tag_loop">
              <div className="tag_wrap">
                <div className="tag_list">
                  <div className="tag_item" data-visual="project_c_1_e_1">社區相處</div>
                  <div className="tag_item" data-visual="project_c_1_e_2">在地品牌塑造</div>
                  <div className="tag_item" data-visual="project_c_1_e_3">設計與藝術策展</div>
                </div>
                <div className="tag_list">
                  <div className="tag_item" data-visual="project_c_1_e_1">社區相處</div>
                  <div className="tag_item" data-visual="project_c_1_e_2">在地品牌塑造</div>
                  <div className="tag_item" data-visual="project_c_1_e_3">設計與藝術策展</div>
                </div>
                <div className="tag_list">
                  <div className="tag_item" data-visual="project_c_1_e_1">社區相處</div>
                  <div className="tag_item" data-visual="project_c_1_e_2">在地品牌塑造</div>
                  <div className="tag_item" data-visual="project_c_1_e_3">設計與藝術策展</div>
                </div>
              </div>
              <div className="tag_wrap">
                <div className="tag_list">
                  <div className="tag_item" data-visual="project_c_1_e_4">在地發展策略</div>
                  <div className="tag_item" data-visual="project_c_1_e_5">建立自主機制</div>
                  <div className="tag_item" data-visual="project_c_1_e_6">田野調查與紀錄</div>
                </div>
                <div className="tag_list">
                  <div className="tag_item" data-visual="project_c_1_e_4">在地發展策略</div>
                  <div className="tag_item" data-visual="project_c_1_e_5">建立自主機制</div>
                  <div className="tag_item" data-visual="project_c_1_e_6">田野調查與紀錄</div>
                </div>
                <div className="tag_list">
                  <div className="tag_item" data-visual="project_c_1_e_4">在地發展策略</div>
                  <div className="tag_item" data-visual="project_c_1_e_5">建立自主機制</div>
                  <div className="tag_item" data-visual="project_c_1_e_6">田野調查與紀錄</div>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_f">
            <div className="column_6">
              <div className="context">
                <h3>首要任務</h3>
                <p>駐村編輯的首要任務，探索地方了解在地的人事物，同時增進與各 社群合作關係的重要過程，本展覽計畫以「萬華」為基地，選擇<b>「剝皮寮歷史街區」</b>為中心，想像作為社宅的駐村編輯空間，進行<strong>「田野調查、職人黃頁、民眾共編」</strong>來認識當地和建立人脈的基礎工作。</p>
              </div>
            </div>
          </section> 

          <section className="row_area_s project_c_1_g">
            <div className="column_6 onset_left_1">
              <div className="context">
                <h5>一・田野調查</h5>
                <p>田野調查，設定以產業和社區二種面向進行周邊資源盤點，社區中也包含市場、廟宇信仰等，如：艋舺服飾商圈、新富町文化市場、龍山寺等地點，並從五個關鍵字<i>「#新舊歷史、#商業觀光、#社區生活、#策展舞台、#社群連結」</i>回應駐村編輯的精神要素，來看各時間切片的地點特質，發現產業與社區，在新舊變化之間，共同營造出的街區魅力與生活特色。社群合作關係的重要過程，本展覽計畫以「萬華」為基地，選擇「剝皮寮歷史街區」為中心，想像作為社宅的駐村編輯空間，進行「田野調查、職人黃頁、民眾共編」來認識當地和建立人脈的基礎工作。</p>
              </div>
              <div className="section_link_btn">
                <Link to="/project_c_1" className="btn_inner">
                  <div className="text_inner">￫</div>
                  <div className="btn_bg"></div>
                </Link>
              </div>
            </div>
          </section> 
          <section className="row_area_s project_c_1_h row_gallery">
            <div className="column_8 gallery_wrap">
              <figure className="figure size_xl">
                <img src={require("../images/project_c/project_c_1_h_1.jpg")} alt="華西街觀光夜市"/>
                <figcaption className="figcaption left">華西街觀光夜市</figcaption>
              </figure>
              <figure className="figure size_l">
                <img src={require("../images/project_c/project_c_1_h_2.jpg")} alt="青山宮"/>
                <figcaption className="figcaption right">青山宮</figcaption>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_1_i">
            <div className="column_5">
              <div className="context">
                <h5>二・職人黃頁</h5>
                <p>針對萬華一帶在地工作者、台北社宅住戶，進行社區人物訪問和專長技能匯集，有：熟悉萬華文史的社區工作者、工業設計背景的城市行動家、陶藝原料的生意人、「想像一個家」計畫的青年藝術家，每個人所關心的課題也不盡相同，如：世代價值觀、生涯的優先順序、網路社交平台的看法等，也建構出駐村編輯的人脈黃頁資料庫和未來行動上的社群互助網絡。</p>
              </div>
            </div>
            <div className="gallery_wrap">
              <div className="section_link_btn">
                <Link to="/project_c_2" className="btn_inner">
                    <div className="text_inner">￫</div>
                    <div className="btn_bg"></div>
                </Link>
              </div>
              <figure className="figure size_max">
                <img src={require("../images/project_c/project_c_1_i-3.png")} alt="二・職人黃頁"/>
                {/* <figcaption className="figcaption left">Superkilen<br/>圖片提供/禾磊藝術</figcaption> */}
              </figure>
            </div>
          </section> 
          {/* <section className="row_area_s project_c_1_j row_gallery">
          </section> */}

          <section className="row_area_s project_c_1_k">
            <div className="column_6 onset_left_1">
              <div className="context">
                <h5>三・民眾共編</h5>
                <p>增加線上與線下的交流介面，建構開放式互動平台，引發群眾大家對於公眾事務和議題的關心，此次以「我們所不知道的萬華」為題，邀請各民眾參與留言，提供更多經常所忽略的在地食物記憶，也成為社宅公共藝術的一種創作機會和可能，期待未來持續多次發起民眾共編，從社區到社會的集體力量，發揮社會行動力和影響力。</p>
              </div>
              <div className="section_link_btn">
                <Link to="/project_c_3" className="btn_inner">
                  <div className="text_inner">￫</div>
                  <div className="btn_bg"></div>
                </Link>
              </div>
            </div>
          </section> 
          <section className="row_area_s project_c_1_l">
            <div className="column_8 dialog_wrap">
              <div className="dialog_item">
                <div className="item_context">
                  <p>新富町市場根本我家廚房啦!<br />這間(指金禾壽司)，我最喜歡！!</p>
                </div>
                <div className="item_person">
                  <img src={require("../images/project_c/project_c_1_l_1.png")} alt=""/>
                </div>
              </div>
              <div className="dialog_item">
                <div className="item_context">
                  <p>有一間越南媽媽開的越式手作料理，叫做『越窩越好』但要去吃要早點去，因為老闆娘要顧小孩，都早早就關門了。</p>
                </div>
                <div className="item_person">
                  <img src={require("../images/project_c/project_c_1_l_2.png")} alt=""/>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_1_m">
            <div className="column_6 onset_left_1">
              <div className="context">
                <h3>走讀/藝術共創工作坊</h3>
                <p>初期完成萬華在地的資源盤點了解後，安排「走讀、藝術共創工作坊 」各三場活動，邀請三位在地工作者和青年藝術家等角色，以萬華車站作為中心，依各走讀主題內容進行三條導覽路徑：文史、食物、藝術；各場走讀活動結束後，接者舉辦三場「藝術共創工作坊」試者帶領參與走讀後的民眾，透過藝術創作共同對於所聆聽的內容進行心得回饋和發揮想像力，三場工作坊共完成三組系列作品，並在線上展覽呈現成果與大家分享，引發更多人對於家與生活的想像。</p>
              </div>
              <div className="section_link_btn">
                <Link to="/project_c_4" className="btn_inner">
                  <div className="text_inner">￫</div>
                  <div className="btn_bg"></div>
                </Link>
              </div>
            </div>
          </section>
          <section className="row_area_s project_c_1_n row_gallery">
            <div className="column_4 gallery_wrap">
              <figure className="figure size_xxl">
                <img src={require("../images/project_c/project_c_1_n_1.jpg")} alt=""/>
                <figcaption className="figcaption left"></figcaption>
              </figure>
              <figure className="figure size_m">
                <img src={require("../images/project_c/project_c_1_n_2.jpg")} alt=""/>
                <figcaption className="figcaption right"></figcaption>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_1_o row_break">
            <div className="column_5">
              <div className="context">
                <h3>願景發展想像</h3>
                <p>於台北社宅公共藝術所提出「駐村編輯」的概念，我們提出整體機制架構下的願景發展圖，可見從駐村編輯所展開的各個工作項目，有：田野調查、走讀、藝術家進駐、藝術共創工作坊、藝術作品、策展等，在社區空間也交織出在地人與外地人共同參與的機會，過程中不斷匯集出職人黃頁、地區資源盤點地圖，更加清楚社區和產業所延伸出的在地生活樣貌；每次階段性的策展任務，將透過舉辦藝術節所產生的展覽、周邊商品、主題活動，也正擴大更多在地社區民眾和外地人響應，最後藝術成為媒介，每場創意的行動都是滾動創造力的民眾互助合作，社群關係與連結，達到自由精神與公民行動。</p>
              </div>
            </div>
          </section>
          <section className="row_area_s project_c_1_p row_gallery">
            <div className="column_4 onset_left_2 gallery_wrap">
              <div className="context">
                <h6>願景發展想像圖</h6>
              </div>
              <div className="figure_overlay">
                <figure className="figure size_full">
                  <img src={require("../images/project_c/project_c_1_p.png")} alt="願景發展想像圖"/>
                  <figcaption className="figcaption left"></figcaption>
                </figure>
              </div>
            </div>
          </section>
          <section className="row_area_s project_c_1_q">
            <div className="column_4 onset_left_4">
              <div className="context">
                <h6>作為台北社宅公共藝術的發展概念，藝術作為媒介與過程也成為了「創造力」的產出，「駐村編輯」所擔任的是駐村田野調查、藝術共創的策劃者，更成為了社群橋樑、洞察社會、引發自主的催化劑，在每一場活動或事件的相遇，不斷鼓勵各種人們自主投入，在合作中交換技能、教學相長，期待從個人到眾人的集體力量，從社區到社會，發揮社會影響力，將是台北社宅公共藝術的另一種機會和可能。</h6>
              </div>
            </div>
          </section>

        </div>

        <div className="head_intro_content headroom">
          <h5 className="name">林書豪</h5>
          <h6 className="title">社區營造工作者 / 星濱山 - 正濱港町藝術共創創辦人</h6>
          <p className="bio">生活於基隆，現為星濱山共創工作室負責人，熱衷於藝術與建築之間思辨的創作和行動，相關作品呈現於社區營造、地方活化、地域策展、藝術教育、空間裝置。畢業自實踐大學建築設計學系研究所，曾在2016日本瀨戶內國祭藝術祭、橫濱黃金町實習，非常喜歡海，認為漁港能帶給他的感動大於城市。自2017至今，在正濱漁港透過藝術共創參與行動計畫，引入「Creator In Residence (CIR)」概念，自籌自辦大型藝術祭的地域策展，與來自各地青年夥伴共同努力下，希望創造百年漁港的文化創意復興，形塑基隆城市的創意基地，近日創建「星濱海港學校」，讓旅人和基隆市民們，獲得接近土地的創作學習，也感受到身心靈的解放，將地方故事和漁港文化也傳遞給更多人!</p>
        </div>

        <div className="page_footer">
          <Link to="/project_d" className="next_page">
            <div className="next_title">養一隻植物和你說說話</div>
            <div className="next_owner">策展人/賴映如</div>
            <div className="next_visual" style={ { backgroundImage: "url(" + next_visual + ")" } }></div>
          </Link>
        </div>

      </div>

    );
  }

}


export default Page
